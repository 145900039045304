import PageWrapper from "./PageWrapper";

const Home = () => {
  return (
    <PageWrapper forceReadableWidth>
      <h1>EXTRA Collective</h1>
      <p>New Release Soon</p>
    </PageWrapper>
  );
};

export default Home;
